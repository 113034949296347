import { useEffect, useState } from "react";

import './style.scss';
import Caillou from "../../assets/imgs/icons/caillou.png";
import Cramer from "../../assets/imgs/icons/cramer.png";

function Slideshow({ variations, reference, isUnique, onSelected }) {
    const [current, setCurrent] = useState(0);
    const [type, setType] = useState(0);

    useEffect(() => {
        onSelected(type, current);
    }, [current, type])

    return (
        <div className="slideshow-container" style={{
            backgroundImage: `url(${require(`../../assets/imgs/${reference}/${variations[current].key}${type === 1 ? "_flame" : ""}.png`)})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top'
        }}>
            <div className="controls">

                <div className="colors">
                    {
                        variations.map((variation, index) => {
                            const isCurrent = index === current;
                            return <div
                                key={index}
                                className="control"
                                style={{
                                    border: `2px solid ${isCurrent ? "white" : "#111"}`,
                                    zoom: isCurrent ? '1.25' : '1',
                                    backgroundColor: variation.color,
                                    boxShadow: `0px 0px 10px rgba(${isCurrent ? '90,90,90' : '255,255,255'},${isCurrent ? '0.5' : '0.1'})`
                                }}
                                onClick={() => {
                                    setCurrent(index)
                                }}></div>
                        })
                    }
                </div>
                {
                    !isUnique && <div className="types">
                        <div className="type" onClick={() => setType(0)} style={{
                            border: `2px solid ${type === 0 ? "white" : "#111"}`,
                            boxShadow: `0px 0px 10px rgba(${type === 0 ? '90,90,90' : '255,255,255'},${type === 0 ? '0.5' : '0.1'})`
                        }}>
                            <img src={Caillou} />
                        </div>
                        <div className="type" onClick={() => setType(1)} style={{
                            border: `2px solid ${type === 1 ? "white" : "#111"}`,
                            boxShadow: `0px 0px 10px rgba(${type === 1 ? '90,90,90' : '255,255,255'},${type === 1 ? '0.5' : '0.1'})`
                        }}>
                            <img src={Cramer} />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Slideshow;
