import * as React from "react";

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Home from "../Home";

import "./style.scss";
import Success from "../Success";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/success",
        element: <Success />,
    },
    {
        path: "/cancel",
        element: <>Cancel</>,
    },
]);

function Router() {
    return (
        <div className="app">
            <RouterProvider router={router} />
        </div>
    );
}

export default Router;
