const STORAGE_NAME = 'cailloute'

const getLocalStorage = () => {
    return JSON.parse(localStorage.getItem(STORAGE_NAME));
}

export default class LocalStorage {
    static getCountry() {
        return getLocalStorage()?.country;
    }

    static getItems() {
        const store = getLocalStorage();
        return store?.items || [];
    }

    static setCountry(country = 'FR') {
        const store = getLocalStorage();
        localStorage.setItem(STORAGE_NAME, JSON.stringify({
            country,
            ...store
        }))
    }

    static addItem(item) {
        const store = getLocalStorage();
        let storeItems = store?.items || [];

        const type = item.type === 0 ? "classique" : "flamme";

        localStorage.setItem(STORAGE_NAME, JSON.stringify({
            ...store,
            items: [...storeItems, {
                id: `${item.reference}-${item.variation.key}-${type}/${new Date().getTime()}`,
                name: item.title,
                weight: item.weight,
                color: `${item.variation.name} ${type}`,
                price: item.price,
                size: item.size
            }]
        }));
    }

    static removeItem(itemId) {
        const store = getLocalStorage();
        localStorage.setItem(STORAGE_NAME, JSON.stringify({
            ...store,
            items: store.items.filter((item) => item.id !== itemId)
        }))
    }

    static clearKart() {
        const store = getLocalStorage();
        localStorage.setItem(STORAGE_NAME, JSON.stringify({
            ...store,
            items: []
        }))
    }
}
