import axios from "axios";

const HOST = 'https://api.cailloute.fr';
//const HOST = 'http://localhost:4600';

const checkout = async (country, items) => {
    let response;

    try {
        response = await axios.post(`${HOST}/create-checkout-session`, { country: country || 'FR', items });
    } catch (err) {
        console.log(err);
        return null;
    }

    return response;
}

const getItems = async () => {
    return await axios.get(`${HOST}/items`);
}

const getSession = async (id) => {
    return await axios.get(`${HOST}/order/success/${id}`);
}

export {
    checkout,
    getItems,
    getSession
}
