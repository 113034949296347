import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

function MotionContainer({ speed, children }) {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: speed,
        staggerDirection: 1,
      },
    },
  };

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
    >
      {children}
    </motion.div>
  );
}

MotionContainer.propTypes = {
  children: PropTypes.node.isRequired,
  speed: PropTypes.number,
};

MotionContainer.defaultProps = {
  speed: 1,
};

export default MotionContainer;
