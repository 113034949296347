import React from 'react';
import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

function AnimatePresenceContainer({ children, renderCondition }) {
  return (
    <AnimatePresence>
      {renderCondition && children}
    </AnimatePresence>
  );
}

AnimatePresenceContainer.propTypes = {
  children: PropTypes.node.isRequired,
  renderCondition: PropTypes.bool.isRequired,
};

export default AnimatePresenceContainer;
