import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';

import Navbar from "../../components/Navbar";
import Header from "../../components/Header";
import MotionContainer from "../../components/Motion/Container";
import MotionItem from "../../components/Motion/Element";
import AnimatePresenceContainer from "../../components/AnimatePresence/Container";
import AnimatePresenceItem from "../../components/AnimatePresence/Element";

import useScroll from "../../services/blockScroll";

import Item from "../../components/Item";
import Delete from "../../assets/svg/delete.svg";

import { checkout, getItems } from "../../services/API";
import LocalStorage from "../../services/localStorage";
import "./style.scss";


function Home() {
    const [country, setCountry] = useState('FR');
    const [items, setItems] = useState([]);
    const [blockScroll, allowScroll] = useScroll();

    const [kartOpened, setKartOpened] = useState(false);
    const [countryModale, setCountryModale] = useState(false);

    const [kart, setKart] = useState([]);

    useEffect(() => {
        setKart(LocalStorage.getItems());
        getItems().then(({ data }) => {
            if (data?.items) {
                setItems(data?.items);
            } else {
                toast.error("Une erreur est survenue...");
            }
        }).catch(err => toast.error("Une erreur est survenue..."));
    }, []);

    useEffect(() => {
        if (countryModale) {
            blockScroll();
        } else {
            allowScroll();
        }
    }, [countryModale]);

    const toogleKart = () => {
        setKartOpened(!kartOpened);
    }

    const itemAdded = () => {
        setKart(LocalStorage.getItems());
    }

    const tooMuchItems = () => {

    }

    const itemInKart = (item, isLast) => {
        const reference = item.id.split("-")[0];
        const type = item.id.includes("-flamme") ? "_flame" : "";
        const color = item.id.replace("-classique", "").replace("-flamme", "").replace(reference, "").slice(1,).split('/')[0].replace('/', '');

        return <div className={`item-in-kart ${isLast ? "last" : ""}`}>
            <div className="img" style={{
                backgroundImage: `url(${require(`../../assets/imgs/${reference}/${color}${type}.png`)})`,
                backgroundSize: 'cover',
                backgroundPosition: 'top'
            }}></div>
            <div className="lbl">
                <h3>
                    <div className="item-label"><b>{item.name}</b></div>
                    <div className="item-color">{item.color} {item.size}</div>
                </h3>
            </div>
            <div>
                <h2>{(item.price / 100).toFixed(2)} €</h2>
            </div>
            <div className="delete-icon" onClick={() => {
                LocalStorage.removeItem(item.id);
                setKart(LocalStorage.getItems());
            }}>
                <img src={Delete} />
            </div>
        </div>
    }

    const chooseCountry = async (country) => {
        try {
            let response = await checkout(country, kart);
            if (response.data) {
                window.location.href = response.data;
            }
        } catch (err) {
            setCountryModale(false)
            toast.error("Une erreur est survenue...");
        }
    }

    return (
        <div className={`home-container ${countryModale ? "no-scroll" : ""}`}>
            <div className="home-container-center">
                <div className="home-header">
                    <Navbar items={kart} onClick={toogleKart} />
                </div>
                <div className="home-content centered">
                    {items && <><MotionContainer speed={0.1}>
                        <div className="items">

                            {
                                items.map((item, index) => {
                                    return <MotionItem key={item.id}>
                                        <Item
                                            item={item}
                                            onClicked={itemAdded}
                                            onTooMuch={tooMuchItems}
                                        />
                                    </MotionItem>
                                })
                            }

                        </div>
                    </MotionContainer></>}
                </div>
                <div className="home-footer centered">
                    <Header />
                </div>
            </div>
            <AnimatePresenceContainer renderCondition={kartOpened}>
                <AnimatePresenceItem>
                    <div className="kart">
                        <span onClick={toogleKart} style={{
                            cursor: 'pointer',
                            textDecoration: 'underline'
                        }}>X Fermer le panier</span>
                        <h2>PANIER ({kart.length} articles)</h2>
                        <div className="kart-items">
                            {kart.map((item, index) => {
                                return itemInKart(item, index === kart.length - 1)
                            })}
                        </div>
                        <div className="bottom-kart">
                            <div className="subtotal">
                                <div>Sous-total</div>
                                <div>
                                    {(kart.reduce((sum, item) => Number(sum) + Number(item.price), 0) / 100).toFixed(2)} €
                                </div>
                                <div>Les frais de livraisons seront calculés en fonction de votre pays de résidence et du poids de votre commande.</div>
                            </div>
                            <div className={`button ${kart.length <= 0 ? "disabled" : ""}`} onClick={() => {
                                setCountryModale(true)
                            }}>Passer commande</div>
                        </div>
                    </div>
                </AnimatePresenceItem>
            </AnimatePresenceContainer>

            <AnimatePresenceContainer renderCondition={countryModale}>
                <AnimatePresenceItem>
                    <div className="country-selector">
                        <div className="overlay" onClick={() => setCountryModale(false)} />
                        <div className="modale-country">
                            <h1>Dans quel pays souhaitez vous vous faire livrer ?</h1>
                            <div className="c">
                                <div onClick={() => chooseCountry('FR')}>
                                    <img src="https://flagsapi.com/FR/shiny/64.png" />
                                    <h3>France</h3>
                                </div>
                                <div onClick={() => chooseCountry('BE')}>
                                    <img src="https://flagsapi.com/BE/shiny/64.png" />
                                    <h3>Belgique</h3>
                                </div>
                                <div onClick={() => chooseCountry('CH')}>
                                    <img src="https://flagsapi.com/CH/shiny/64.png" />
                                    <h3>Suisse</h3>
                                </div>
                                <div onClick={() => chooseCountry('CA')}>
                                    <img src="https://flagsapi.com/CA/shiny/64.png" />
                                    <h3>Canada</h3>
                                </div>

                            </div>
                        </div>
                    </div>
                </AnimatePresenceItem>
            </AnimatePresenceContainer>
        </div>
    );
}

export default Home;
