import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';

import { useSearchParams } from "react-router-dom";
import { getSession } from "../../services/API";
import Cailloute from "../../assets/imgs/cailloute.png"

import "./style.scss";

function Success() {
    let [searchParams] = useSearchParams();
    const [session, setSession] = useState(null);

    useEffect(() => {
        const session_id = searchParams.get("session_id");
        getSession(session_id).then((session) => {
            setSession(session.data);
        }).catch(err => {
            toast.error("Une erreur est survenue...");
        });
    }, []);

    const getAddress = () => {
        return <>{session.customer_details.address.line1}{session.customer_details.address.line2 && " " + session.customer_details.address.line2}, {session.customer_details.address.city} {session.customer_details.address.postal_code} {session.customer_details.address.country}</>
    }

    return (
        <div className={`success-container`}>
            {
                session && <>
                    <div className="img"><img src={Cailloute} /></div>
                    <div className="header">COMMANDE <br /><span>{searchParams.get("order_id")}</span></div>
                    <div className="thanks">Merci {session.customer_details.name.split(" ")[0]} pour ta commande !</div>
                    <div className="address">
                        <div>Elle sera expédiée au</div>
                        <b>{getAddress()}</b>
                    </div>
                    <div className="mail">
                        Si tu as la moindre question concernant ta commande, n'hésite pas à me contacter par mail
                    </div>
                    <div className="contact">
                        <a href="mailto:contact@cailloute.fr">Me contacter</a>
                    </div>
                </>
            }
        </div>
    );
}

export default Success;
