
import Twitch from "../../assets/svg/twitch.svg";
import Twitter from "../../assets/svg/twitter.svg";
import Instagram from "../../assets/svg/instagram.svg";
import Kofi from "../../assets/svg/kofi.svg";

import './style.scss';

function Header() {
    return (
        <div className="header-container">
            <div className="header-content">
                <div className="side">
                    © Cailloute, 2024 - Tous droits réservés
                </div>
                <div className="made">
                    Made by <a href="https://twitter.com/jussetain" target="_blank">greg</a> with 💖
                </div>
                <div className="side soc">
                    <div className="socials">
                        <a href="https://twitch.com/cailloute" target='_blank'><img className="social twitch" src={Twitch} /></a>
                        <a href="https://instagram.com/cailloute" target='_blank'><img className="social instagram" src={Instagram} /></a>
                        <a href="https://ko-fi.com/cailloute" target='_blank'><img className="social kofi" src={Kofi} /></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
