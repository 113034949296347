import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

function AnimatePresenceItem({ children }) {
  const initial = { opacity: 0, height: 0, zIndex: 20 };
  const animate = { opacity: 1, height: 'auto', zIndex: 20 };

  return (
    <motion.div
      style={{ overflow: 'hidden' }}
      initial={initial}
      animate={animate}
      transition={{ duration: 0.1 }}
      exit={initial}
      key="container"
    >
      {children}
    </motion.div>
  );
}

AnimatePresenceItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AnimatePresenceItem;
