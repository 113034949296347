import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

function MotionItem({ children }) {
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'tween',
      },
    },
  };

  return (
    <motion.div variants={item}>
      {children}
    </motion.div>
  );
}

MotionItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MotionItem;
