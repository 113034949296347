import { motion, useAnimationControls } from "framer-motion"

import Cailloute from "../../assets/imgs/cailloute.png";
import Cart from "../../assets/svg/cart.svg";

import './style.scss';
import { useEffect } from "react";

function Navbar({ items, onClick }) {
    const controls = useAnimationControls();

    const animation = async () => {
        await controls.start({ scale: 1.2 });
        controls.start({ scale: 1 });
    }

    const animationTooMuch = async () => {
        await controls.start({ scale: 1.2, backgroundColor: 'red' });
        controls.start({ scale: 1, backgroundColor: 'rgb(255, 166, 0)' });
    }

    useEffect(() => {
        if (items.length >= 30) {
            animationTooMuch();
        } else if (items.length > 0) {
            animation();
        }
    }, [items])

    return (
        <div className="panel-content centered">
            <div className="panel-image" /*onClick={() => LocalStorage.clearKart()}*/>
                <img src={Cailloute} />
            </div>
            <div className="panel-title">
                <div className="title">CAILLOUTE.shop</div>
            </div>
            <div className="panel-kart" onClick={onClick}>
                <img src={Cart} />
                <motion.div animate={controls} className={`cart-quantity ${Object.keys(items).length > 0 ? 'orange' : ''}`}>{items ? Object.keys(items).length : 0}</motion.div>
            </div>
        </div>
    );
}

export default Navbar;
